// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_3262 #slider {
  position: relative;
  height: 100vh;
  max-height: 100vh;
  min-height: 720px;

  &:before {
    position: absolute;
    content: "";
    background: url(/i/design/logo-watermark.png);
    height: 397px;
    width: 366px;
    left: 0;
    top: 5px;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  .backstretch {

    img {
      animation: zoom 30s ease forwards infinite;

      @keyframes zoom {
        from {
          transform: scale(1)
        }

        to {
          transform: scale(1.1)
        }
      }
    }
  }

}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  @media only screen and (max-width: 990px) {
    position: fixed !important;
    height: 100vh !important;
  }

  // for top of page gradient
  &:before {
    @include before(100%, 353px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  // for btm of page gradient
  &:after {
    @include before(100vw, 233px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom center;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 50px;
}